import authenticationUtils from '@/utils/authentication.js';
import getEnv from '@/utils/env.js';
import i18n from '@/utils/i18n'

// setup AbortController
let controller = new AbortController();
let signal = controller.signal;

// var use for wait request
let isWaiting = false;

let currentState = {};
currentState.state = '';

function getApiUrl(route)
{
    const url = getEnv('VUE_APP_API_URL')
    if(!url) throw new Error('No API URL')
    return url + route
}

function abortRequest()
{
    controller.abort()
    isWaiting = false;
}

async function fetchRequest(path, headers, method, formData, type = 'json')
{
    // abort previous query
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;

    const options = {};

    if (method) options.method = method;
    if (headers) options.headers = headers;
    if (formData) options.body = formData;
    options.signal = signal;

    const response = await fetch(path, options);

    if (response.ok) {
        if (type === 'json') {
            const json = await response.json().catch(() => {return false;});
            return json;
        }
        if (type === 'blob') {
            const blob = await response.blob().catch(() => {return false;});
            return blob;
        }
    }else{
        return false;
    }
}

async function loginRequest(authenticationData)
{
    currentState.state = i18n.global.t('global.loader.loginRequest');
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${authenticationData.authorizationToken}`,
    };
    const body = `identSession=${authenticationData.idEntSession}&pwd=${process.env.VUE_APP_PASSWORD}&mail=web_login`;

    const url = getApiUrl('/register/login');
    if(!url) throw new Error('Login URL is not defined');

    const request = await fetchRequest(url, headers, 'POST', body);

    if (request) {
        return request;
    }else{
        throw new Error('Error during login request');
    }
}

async function buildSessionRequest(loginData, authenticationData)
{
    currentState.state = i18n.global.t('global.loader.buildSession');
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${authenticationData.authorizationToken}`,
    };

    const body = `identSession=${authenticationData.idEntSession}&identToken=${loginData.identToken}&authToken=${loginData.authToken}`;
    
    const url = getApiUrl('/build/create');
    if(!url) throw new Error('Build session URL is not defined');

    const request = await fetchRequest(url, headers, 'POST', body);

    if (request) {
        return request;
    }else{
        throw new Error('Error during session creation');
    }
}

async function uploadFileRequest(loginData, buildData, authenticationData, audioFile, videoDuration)
{
    currentState.state = i18n.global.t('global.loader.uploadRequest');
    let config = new File([`MUSIC ${audioFile.name}\nANALYSIS 1\nDURATION ${videoDuration}\nN 3`], 'mm_mts_config.txt', {
        type: "text/plain"
    });
    const headers = {
        'Authorization': `Basic ${authenticationData.authorizationToken}`,
    };

    const formData = new FormData();
    formData.append('identSession', authenticationData.idEntSession);
    formData.append('identToken', loginData.identToken);
    formData.append('authToken', loginData.authToken);
    formData.append('buildSession', buildData.buildSession);
    formData.append('config', config);
    formData.append('musicFile', audioFile);
    
    const url = getApiUrl('/build/upload');
    if(!url) throw new Error('Upload URL is not defined');

    const request = await fetchRequest(url, headers, 'POST', formData);    

    if (!request) {
        throw new Error('Error during uploading file');
    }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function verificationRequest(loginData, buildData, authenticationData)
{
    currentState.state = i18n.global.t('global.loader.processFiles');
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${authenticationData.authorizationToken}`,
    };

    const body = `identSession=${authenticationData.idEntSession}&identToken=${loginData.identToken}&authToken=${loginData.authToken}&buildSession=${buildData.buildSession}`;
    
    const url = getApiUrl('/build/wait');
    if(!url) throw new Error('Verification URL is not defined');

    isWaiting = true;
    while(isWaiting) {
        let request = await fetchRequest(url, headers, 'POST', body);
        if (request) {
            if (request.finish === 'true') {
                isWaiting = false;
                return request.jsonResult;
           }
        }else{
            isWaiting = false;
            throw new Error('Error during verification build');
        }
        // Wait 5 seconds
        await sleep(5000);
    }
    return false;
}

async function getAudioFile(urlArray)
{
    currentState.state = i18n.global.t('global.loader.downloadRequest');
    let authorization = authenticationUtils.authenticationData();
    const array = [];
    const headers = {
        'Authorization': `Basic ${authorization.authorizationToken}`,
    };
    for (let index = 0; index < urlArray.length; index++) {
        const response = await fetchRequest(urlArray[index], headers, 'GET', '', 'blob');
        array.push(window.URL.createObjectURL(response));
    }
    return array;
}

async function buildVariation(audioFile, videoDuration)
{
    controller.abort();
    isWaiting = false;
    const authenticationData = authenticationUtils.authenticationData();
    try {
        const login = await loginRequest(authenticationData);
        const buildSession = await buildSessionRequest(login, authenticationData);
        await uploadFileRequest(login, buildSession, authenticationData, audioFile, videoDuration);
        let verificationBuild = await verificationRequest(login, buildSession, authenticationData);
        return verificationBuild;
    } catch (error) {
        if(!signal.aborted)throw new Error(error);
    }
}

export default {
    buildVariation,
    getAudioFile,
    abortRequest,
    currentState
}

