export default {
    fileUploader: {
        video: {
            defaultMsg: '<span>1</span> Drag and drop a video file here or click',
            removeBtn: 'Remove',
            previewMsg: 'Drag and drop a video file or click to replace',
            accept: 'video/*'
        },
        audio: {
            defaultMsg: '<span>2</span> Drag and drop an audio file here or click',
            removeBtn: 'Remove',
            previewMsg: 'Drag and drop an aduio file or click to replace',
            accept: 'audio/mpeg,audio/x-wav,audio/wav'
        }

    }
}