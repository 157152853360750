export default {
    presentationBlock: {
        title: "Music AutoEditor for Audiovisual Production",
        description : {
            textOne: ' is an AI-empowered music editing solution that performs automatic synchronization with a video.',
            textTwo: ' includes a straightforward graphical interface specifically developed for professionals: sync managers, music supervisors, audio/video production teams, audio/video editors, audio/video creation teams.',
            textThree: 'It is simply amazing. The result has a musical quality that respects the original artwork, while giving you full control over the musical construction.'
        },
        mainContent: {
            left: {
                textOne: ' automatically generates from any music file several high-quality short music edits to match your video. You simply pick-up the one your prefer.',
                textTwo: ' will not replace your creativity nor your expertise as an audio editor. It will however increase your productivity and speed-up the process with your team and with your customers. You will get better ROI when answering a brief, better reactivity when answering a pressing editing demand, better impact when sharing your musical choices, and increased autonomy when working without a professionnal editor at hand.',

            },
            right: {
                textOne: ' graphical interface includes simple options to guide the music editing :',
                list: {
                    textOne: 'selection of begin/end positions for the music on the video',
                    textTwo: 'replace or mix with existing audio, including music compression over voice',
                    textThree: 'a batch-mode for processing tens of music files in a row for a same video',
                    textFour: 'selection of climax and highligths positions on the video, selection of parts of original music to appear on video, ...'
                }
            },
            footer: {
                textOne: 'No added rights. No stretching or pitch modification. Music AutoEditor simply emulates the work of a professional audio editor.',
                textTwo: 'The user must own the proper rights over both the video and music files in order to publish results generated with Music AutoEditor.'
            }
        }

    }
}
