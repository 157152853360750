export default {
    global: {
        name: "Music AutoEditor",
        longName: 'The Music AutoEditor',
        footer: 'MUSEMIND - 4 avenue de Cambridge, 14200 Hérouville St Clair, France - tous droits réservés - mentions légales - politique de confidentialité ',
        alert: {
            video: {
                duration:{
                    tooLong: 'The duration of your video is too long, it should not exceed 10 minutes.',
                    noDuration : 'The duration of your video is not conform, it should being more than 0 seconds.'
                } 
                
            },
            audio: {
                duration : {
                    lessThanVideo: 'The duration of your audio is not conform, it should  being more than video duration.',
                    noDuration: 'The duration of your audio is not conform, it should being more than 0 seconds.'
                }
            },
            onError: 'Unable to load your file, please check its type ...'
        },
        video: {
            noVideo: "No video",
            removeFile: "Remove video file"
        },
        audio: {
            removeFile: "Remove audio File",
            title: 'Your original music',
            noAudio: "No audio"
        },
        build: {
            buildButton: 'Build music edits'
        },
        loader: {
            waitMessage: 'Please wait 1 min',
            cancelButton: 'Cancel',
            loginRequest: 'Connection to the server',
            buildSession: 'Setup build session',
            uploadRequest: 'Uploading files',
            processFiles: 'Waiting for files process',
            downloadRequest: 'Downloading results'
        },
        variation:{
            select: 'PLAY MUSIC EDIT'
        },
    }
}
