<template>
    <div :style="{'display': visible ? 'block' : 'none'}">
        <div class="container-opacity"></div>
        <div class="popup">
            <div class="textTitre">
                {{message}} - {{$t('global.loader.waitMessage')}}
            </div>
            <div class="container-image">
                <div class="image"></div>
                <div :ref="el => {if(el) barPrimary = el}" id="barPrimary"></div>
                <div :ref="el => {if (el) barSecondary = el}" id="barSecondary"></div>
            </div>
            <div class="divButton">
                <a class="cancelButton btn btn-action" @click="cancelClick" type="button">
                    {{$t('global.loader.cancelButton')}}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import progressBar from '@/assets/images/sound-progressbar.png';
export default {
    components: {
    },
    props: {
        loadingMessage: {
            type: String,
            default: ''
        },
        parentSelector: {
            type: String,
            default: ''
        },
        switchVisible: {
            type: Boolean,
            default: false
        },
        backdropOpacity: {
            type: Number,
            default: 0.4
        }
    },
    setup(props, {emit}) {
        //DATA
        const visible = ref(false);
        const barPrimary = ref('');
        const barSecondary = ref('');
        const primaryColor = ref('#40317c');
        const secondaryColor = ref('white');
        const idSetInterval = ref('');
        const message = ref(props.loadingMessage);
        //watcher
        watch(() => props.switchVisible, value => {
            visible.value = value;
            if (visible.value === true) {
                runProgressBar();
            }else{
                clearInterval(idSetInterval.value);
            }
        });
        watch(() => props.loadingMessage, value => {
            message.value = value;
        })
        //methods
        const runProgressBar = (() => {
            barPrimary.value.style.backgroundColor = primaryColor.value;
            barSecondary.value.style.backgroundColor = secondaryColor.value;
            let width = 1;
            idSetInterval.value = setInterval(frame,30);
            function frame(){
                if (width >= 100) {
                    width = 1;
                    barPrimary.value.style.width = width +'%';
                    barPrimary.value.style.backgroundColor = barPrimary.value.style.backgroundColor === secondaryColor.value ? primaryColor.value : secondaryColor.value;
                    barSecondary.value.style.backgroundColor = barSecondary.value.style.backgroundColor === secondaryColor.value ? primaryColor.value : secondaryColor.value;

                }else{
                    width++;
                    barPrimary.value.style.width = width +'%';
                }
            }
        });
        const cancelClick = (() => {
            clearInterval(idSetInterval.value);
            emit("switchLoading");
            emit("abortBuild");
            emit('clearLoadingMessage')
        });
        return{
            message,
            idSetInterval,
            barSecondary,
            barPrimary,
            visible,
            progressBar,
            primaryColor,
            secondaryColor,
            runProgressBar,
            cancelClick
        }
    },
}
</script>
<style lang="scss" scoped>
.container-opacity{
    top:0;
    left:0;
    height: 100vh;
    position: fixed;
    width: 100%;
    opacity:0.7;
    background-color: gray;
}
.divButton{
    text-align: center;
    margin-top: 4%;
}
.cancelButton{
    width: 200px;
    background-color: #21212b;
    color: #C4C4C4;
}
.textTitre{
    text-align: center;
    color: #555555;
    font-weight: bold;
    margin-top: 3%;
}
.popup{
    position: fixed;
    z-index: 999;
    height: 200px;
    width: 390px;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #C4C4C4;
    border-radius: 10px;
}
@media (min-width: 1400px) {
.popup{
    height: 240px;
}
}
.container-image{
    width: 100%;
    height: 55%;
}
.image{
    width: 100%;
    height: inherit;
    background-image: url('@/assets/images/sound-progressbar.png');
    background-size: cover;
    z-index: 10;
    position: absolute;
    
}
#barPrimary{
    position: absolute;
    z-index: 2;
    width: 20%;
    height: inherit;
    background-color: #40317c;
}
#barSecondary{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: inherit;
    background-color: white;
}
</style>
