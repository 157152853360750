export default {
    mainBlock: {
        presentation: "Music AutoEditor is an AI solution for automatic music editing on video.",
        try: "Try it for free",
        steps: {
            textOne: "drop your video",
            textTwo: "drop your music track",
            textThree: "click to build music edits "
        },
        contact: {
            header: "If you are interested, please contact us for any questions or demonstration requests.",
            politePhrase: "You can contact ... at :",
            person: {
                name: 'Nicolas',
                mail: {
                    reverse: "moc.cisum-dnimesum{'@'}reinnomeln",
                    normal: "nlemonnier{'@'}musemind-music.com"
                },
                tel: '+33 (0)763 302 799'
            }
        },
        buttons: {
            discover: {
                text: 'DISCOVER AUTOEDITOR'
            },
            discoverFooter: {
                text: 'DISCOVER MUSEMIND'
            }
        }

    }
}
