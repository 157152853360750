//French trads
import frMainBlock from '@/lang/fr/frMainBlock';
import frMDBFileUploader from '@/lang/fr/frMDBFileUploader.js';
import frPresentationBlock from './fr/frPresentationBlock';
import frGlobal from './fr/frGlobal';
//English trads
import enMainBlock from '@/lang/en/enMainBlock';
import enMDBFileUploader from '@/lang/en/enMDBFileUploader.js';
import enPresentationBlock from './en/enPresentationBlock';
import enGlobal from './en/enGlobal';

export default{
    fr: Object.assign(
        {},
        frMainBlock,
        frMDBFileUploader,
        frPresentationBlock,
        frGlobal
    ),
    en: Object.assign(
        {},
        enMainBlock,
        enMDBFileUploader,
        enPresentationBlock,
        enGlobal
    )
}