<template>
    <div>
        <h3>{{$t('mainBlock.contact.header')}}</h3>
        <div class="row">
            <div class="col-lg-9">
                <p>{{$t('mainBlock.contact.politePhrase').replace('...', $t('mainBlock.contact.person.name'))}}
                    <br/>
                    <span class="reverse" @click="mailTo">{{$t('mainBlock.contact.person.mail.reverse')}}</span> 
                    / {{$t('mainBlock.contact.person.tel')}}
                </p>
            </div>
            <div class="col-lg-3 text-md-center text-lg-end">
                <p><a href="https://musemind-music.com/home-en/" target="_blank" class="btn btn-discover" role="button">{{$t('mainBlock.buttons.discoverFooter.text')}}</a></p>
            </div>
        </div>

    </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
export default {
    setup() {

         const { t } = useI18n({useScope: 'global'});

        const mailTo = (() => {
            window.location = `mailto:${t('mainBlock.contact.person.mail.normal')}`;
        });
        return {
            mailTo
        }
    },
}
</script>
<style scoped>

</style>
