<template>
    <div class="first-block">
        <div class="row">
            <div class="col-lg-6">
                <div class="desc-musemind">
                    <img src="@/assets/images/logo-musemind.svg" alt="Muse Mind">
                    <h1>Music AutoEditor</h1>
                    <p class="presentation">{{$t('mainBlock.presentation')}}</p>
                    <p class="try">{{$t('mainBlock.try')}}</p>
                    <div class="etapes">
                        <span>1</span>
                        {{$t('mainBlock.steps.textOne')}}
                        <span>2</span>
                        {{$t('mainBlock.steps.textTwo')}}
                        <span>3</span>
                        {{$t('mainBlock.steps.textThree')}}
                    </div>
                    <hr/>
                    <!--
                    <h3>{{$t('mainBlock.contact.header')}}</h3>
                    <p>{{$t('mainBlock.contact.politePhrase').replace('...', $t('mainBlock.contact.person.name'))}}
                        <br>
                       <span class="reverse" @click="mailTo">{{$t('mainBlock.contact.person.mail.reverse')}}</span>
                        - {{$t('mainBlock.contact.person.tel')}}
                    </p>
                    -->
                </div>
                <div class="box-btn-discover d-none d-lg-block">
                    <a href="#MuseMind" class="btn btn-discover" role="button">{{$t('mainBlock.buttons.discover.text')}}</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="tool-musemind">
                    <EditorBlock :ref="el => {if(el) editorEl = el}" @switchLoading="switchLoading" @loadingMessage="loadingMessage" />
                    <LoaderItem :loadingMessage="message" :switchVisible="loading" parentSelector=".tool-musemind" @switchLoading="switchLoading" @abortBuild="abortBuild" @clearLoadingMessage="clearLoadingMessage"/>
                </div>
            </div>
            <div class="col-lg-6 d-lg-none">
                <div class="box-btn-discover">
                    <a href="#MuseMind" class="btn btn-discover" role="button">{{$t('mainBlock.buttons.discover.text')}}</a>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import EditorBlock from '@/components/views/EditorBlock.vue';
import LoaderItem from '@/components/MDB/LoaderComponent.vue';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/reactivity';
export default {
    components: {
        EditorBlock,
        LoaderItem
    },
    setup() {
        //DATA
        const { t } = useI18n({useScope: 'global'});
        const loading = ref(false);
        const editorEl = ref('');
        const message = ref(t('global.loader.loginRequest'));

        //METHODS
        const switchLoading = (() => {
            loading.value = !loading.value;
        });
        const abortBuild = (() => {
            editorEl.value.abortBuild();
        });
        const mailTo = (() => {
            window.location = `mailto:${t('mainBlock.contact.person.mail.normal')}`;
        });
        const loadingMessage = ((value) => {
            if(value === '') message.value = t('global.loader.loginRequest');
            else{
                message.value = value;
            }
            
        });
        const clearLoadingMessage = (() => {
            editorEl.value.clearLoadingMessage();
        });
        return {
            message,
            loading,
            editorEl,
            loadingMessage,
            switchLoading,
            mailTo,
            abortBuild,
            clearLoadingMessage
        }
    },
}
</script>
<style scoped>

</style>
