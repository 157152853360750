<template>
    <div>
        <MDBFileUpload
            :ref="el => {if(el) uploaderEl = el}"
            :defaultMsg="defaultMsg"
            :removeBtn="removeBtn"
            :previewMsg="previewMsg"
            :accept="accept"
            :height="height"
            @change="onChange"
        />
    </div>
</template>
<script>
import { MDBFileUpload } from "mdb-vue-file-upload";
import { onMounted, ref } from "vue";
export default {
    components: {
        MDBFileUpload
    },
    props: {
        defaultMsg: {
            type: String,
            default: ''
        },
        removeBtn: {
            type: String,
            default: ''
        },
        previewMsg: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: '*'
        },
        height: Number,
    },
    setup(props, {emit}) {
        //DATA
        const uploaderEl = ref('');
        //METHODS
        const onChange = ((event) => {
            if (event.length === 0) return;
            if (checkFormat(event[0])) {
                emit('onChange', event);
            }else{
                uploaderEl.value.clear();
                emit('onError');
            }
        });

        const checkFormat = ((file) => {
            const acceptedArray = props.accept.split(',');
            //on regarde si notre tableau de valeurs acceptées contient une valeur globale => video/* ou audio/*
            const found = acceptedArray.find(el => {
                if (el.endsWith('*')) {
                    const prefix = el.split('/')[0];
                    if ( file.type.startsWith(prefix) )  return true;
                }
            });
            if ( found != undefined) return true;
            if  (acceptedArray.includes(file.type))  return true;//sinon on regarde si le type du fichier est contenu dans notre tableau 
            else return false ;//sinon le type du fichier n'est pas autorisé
        });

        onMounted(() => {
            const el =uploaderEl.value.$el.querySelector(".file-upload-default-message");
            el.innerHTML = el.innerText;

        });

        return {
            uploaderEl,
            onChange,
            checkFormat
        }
    },
}
</script>
<style scoped>

</style>