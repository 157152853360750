<template>
    <div v-if="videoFile === ''" class="box-drop-video">
        <FileUploader
            :defaultMsg="$t('fileUploader.video.defaultMsg')"
            :removeBtn="$t('fileUploader.video.removeBtn')"
            :previewMsg="$t('fileUploader.video.previewMsg')"
            :accept="$t('fileUploader.video.accept')"
            @onError="onError"
            @onChange="videoUploaded"
        />
    </div>
    <div v-else>
        <div class="lecteur-video">
            <video :ref="el => { if(el) videoEl = el }" controls :src="urlVideo">{{$t('global.video.noVideo')}}</video>
        </div>
        <div v-if="audioFile === ''" class="box-drop-audio">
            <FileUploader
                :defaultMsg="$t('fileUploader.audio.defaultMsg')"
                :removeBtn="$t('fileUploader.audio.removeBtn')"
                :previewMsg="$t('fileUploader.audio.previewMsg')"
                :accept="$t('fileUploader.audio.accept')"
                :height="100"
                @onChange="audioUploaded"
                @onError="onError"
            />
        </div>
        <div v-else>
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col">{{$t('global.audio.title')}}</th>
                        <th scope="col">
                            <div v-if="buildState">{{$t('global.variation.select')}}</div>
                            <audio :ref="el => { if(el) audioEl = el }" :src="urlAudio">{{$t('global.audio.noAudio')}}</audio>
                            <audio :ref="el => { if(el) audioVariations['1'] = el }"  :src="url1">{{$t('global.audio.noAudio')}}</audio>
                            <audio :ref="el => { if(el) audioVariations['2'] = el }"  :src="url2">{{$t('global.audio.noAudio')}}</audio>
                            <audio :ref="el => { if(el) audioVariations['3'] = el }"  :src="url3">{{$t('global.audio.noAudio')}}</audio>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{audioName}}</td>
                        <td>
                            <a v-if="!buildState" @click="buildVariation" class="btn btn-action" type="button"><span>3</span>{{$t('global.build.buildButton')}}</a>
                            <div v-else>
                                <button @click="clickVariation('1')" type="button" class="btn btn-floating btn-play" :class="currentVariation === '1' ? 'btn-playing' : 'btn-light'">
                                    <div v-if="currentVariation === '1'">
                                        <div v-if="!isPlaying">
                                            <i class="fas fa-play"></i>
                                        </div>
                                        <div v-else>
                                            <i class="fas fa-pause"></i>
                                        </div>
                                    </div>
                                    <div v-else>
                                        1 <i class="fas fa-waveform"></i>
                                    </div>
                                </button>
                                <button @click="clickVariation('2')" type="button" class="btn btn-floating btn-play" :class="currentVariation === '2' ? 'btn-playing' : 'btn-light'">
                                    <div v-if="currentVariation === '2'">
                                        <div v-if="!isPlaying">
                                            <i class="fas fa-play"></i>
                                        </div>
                                        <div v-else>
                                            <i class="fas fa-pause"></i>
                                        </div>
                                    </div>
                                    <div v-else>
                                        2 <i class="fas fa-waveform"></i>
                                    </div>
                                </button>
                                <button @click="clickVariation('3')" type="button" class="btn btn-floating btn-play" :class="currentVariation === '3' ? 'btn-playing' : 'btn-light'">
                                    <div v-if="currentVariation === '3'">
                                        <div v-if="!isPlaying">
                                            <i class="fas fa-play"></i>
                                        </div>
                                        <div v-else>
                                            <i class="fas fa-pause"></i>
                                        </div>
                                    </div>
                                    <div v-else>
                                        3 <i class="fas fa-waveform"></i>
                                    </div>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
            <div>
                <a @click="removeAudio" v-if="audioFile != ''" class="btn btn-action" type="button"><span><i class="fas fa-music"></i></span>{{$t('global.audio.removeFile')}}</a>
            </div>
            <div>
                <a @click="removeVideo" v-if="videoFile != ''" class="btn btn-action" type="button"><span><i class="fas fa-video"></i></span>{{$t('global.video.removeFile')}}</a>
            </div>
        </div>
    </div> 
    <MDBAlert
        @hidden="alert1 = false"
        dismiss
        v-model="alert1"
        color="danger"
        position="bottom-center"
        width="535px"
        autohide
        :delay="5000"
    >
        {{alertMessage}}
    </MDBAlert> 
</template>
<script>
import FileUploader from '@/components/MDB/FileUploader.vue';
import { ref, watch } from "vue";
import { MDBAlert} from 'mdb-vue-ui-kit';
import videoConf from '@/conf/video.js';
import fetchUtils from '@/utils/fetch.js';
import { useI18n } from 'vue-i18n';
export default {
    components: {
        FileUploader,
        MDBAlert
    },
    setup(props, {emit}) {
        //DATA
        const videoFile = ref('');
        const urlVideo = ref('');
        const videoEl = ref('');

        const audioEl = ref('');
        const audioFile = ref('');
        const urlAudio = ref('');
        const audioName = ref('');

        const alertMessage = ref('');
        const alert1 = ref(false);

        const idEntSession = ref('');

        const url1 = ref('');
        const url2 = ref('');
        const url3 = ref('');
        const audioVariations = ref([]);
        const currentVariation = ref('1');

        const buildState = ref(false);

        const isPlaying = ref(false);

        const loadingMessage = ref('');

        const idSetInterval = ref('');

        const { t } = useI18n({useScope: 'global'});

        //WATCHERS
        watch(() => videoEl.value, () => {
            videoEl.value.onerror = function () {
                removeVideo();
                alertMessage.value = t('global.alert.onError');
                alert1.value = true;
            };
            
            videoEl.value.onloadedmetadata = function () {
                let errorDuration = false;
                if (this.duration > videoConf.getDuration()) {errorDuration = true; alertMessage.value = t('global.alert.video.duration.tooLong');}
                if (this.duration <= 0) {errorDuration = true; alertMessage.value = t('global.alert.video.duration.noDuration');}
                if (errorDuration) {
                    removeVideo();
                    alert1.value = true;
                }
            };
        });

        watch(() => audioEl.value, () => {
            audioEl.value.onerror = function () {
                removeAudio();
                alertMessage.value = t('global.alert.onError');
                alert1.value = true;
            };

            audioEl.value.onloadedmetadata = function () {
                let errorDuration = false;
                if (this.duration < videoEl.value.duration) { errorDuration = true; alertMessage.value = t('global.alert.audio.duration.lessThanVideo');}
                if (this.duration <= 0) { errorDuration = true; alertMessage.value = t('global.alert.audio.duration.noDuration');}
                if (errorDuration) {
                    removeAudio();
                    alert1.value = true;
                }
            };
        });

        watch(() => loadingMessage.value, () => {
            emit('loadingMessage', loadingMessage.value)
        });
        
        //METHODS
        const videoUploaded = ((event) => {
            event[0] ? videoFile.value = event[0] : videoFile.value = '';
             urlVideo.value = window.URL.createObjectURL(videoFile.value);
        });
        const audioUploaded = ((event) => {
            audioName.value = event[0].name;
            //on créé un nouveau file avec un nom générique pour éviter des problèmes lors du processus
            event[0] ? audioFile.value = new File([event[0]], 'musicFile.mp3',{type: event[0].type}) : audioFile.value = '';
            urlAudio.value = window.URL.createObjectURL(audioFile.value);
        });
        const removeVideo = (() => {
            window.URL.revokeObjectURL(urlVideo.value);
            urlVideo.value = '';
            videoFile.value = '';
            if (audioFile.value != '') {
                removeAudio();
            }
        });
        const removeAudio = (() => {
            window.URL.revokeObjectURL(urlAudio.value);
            if(url1.value != '')  window.URL.revokeObjectURL(url1.value);
            if(url2.value != '')  window.URL.revokeObjectURL(url2.value);
            if(url3.value != '')  window.URL.revokeObjectURL(url3.value);
            buildState.value = false;
            audioFile.value = '';
            urlAudio.value = '';
            audioName.value = '';

        });
        const onError = (() => {
            alertMessage.value = t('global.alert.onError');
            alert1.value = true;
        });

        const buildVariation = ( async () => {
            // Disable build button
            try {
                checkCurrentState();
                emit('switchLoading');
                let URLs = await fetchUtils.buildVariation(audioFile.value, videoEl.value.duration);
                if(!URLs) return;
                URLs = URLs.replace(/\n|\r| |\\|"|\[|\]/g,'');
                const array = URLs.split(',');
                const arrayUrl = [];
                arrayUrl.push(array[1]);
                arrayUrl.push(array[3]);
                arrayUrl.push(array[5]);

                const blobs = await fetchUtils.getAudioFile(arrayUrl);

                url1.value = blobs[0];
                url2.value = blobs[1];
                url3.value = blobs[2];
                buildState.value = true;
                videoEl.value.controls = false;
                videoEl.value.muted = true;
                emit('switchLoading');
                clearLoadingMessage();
            } catch (error) {
                emit('switchLoading');
                clearLoadingMessage();
                alertMessage.value = (error);
                alert1.value = true;
            }
            // Enable build button

        });

        const clickVariation = ((index) => {
            if (isPlaying.value) {
                if (currentVariation.value === index) {
                    audioVariations.value[index].pause();
                    videoEl.value.pause();
                    isPlaying.value = false;
                }else{
                    isPlaying.value = true;

                    audioVariations.value[currentVariation.value].pause();
                    audioVariations.value[currentVariation.value].currentTime = 0;

                    videoEl.value.currentTime = 0;
                    videoEl.value.play();

                    audioVariations.value[index].play();
                    currentVariation.value = index;
                }
            }else{
                if (currentVariation.value === index) {
                    isPlaying.value = true;
                    audioVariations.value[index].play();
                    videoEl.value.play();
                }else{
                    isPlaying.value = true;

                    videoEl.value.currentTime = 0;
                    videoEl.value.play();
                    if (currentVariation.value != '') {audioVariations.value[currentVariation.value].currentTime = 0;}
                    audioVariations.value[index].play();
                    currentVariation.value = index
                }
            }
        });

        const abortBuild = (() => {
            fetchUtils.abortRequest();
        });

        const checkCurrentState = (() => {
            idSetInterval.value = setInterval(frame,1000);
            function frame(){
                if(fetchUtils.currentState.state != loadingMessage.value) loadingMessage.value = fetchUtils.currentState.state;
            }
        });
        const clearLoadingMessage = (() => {
            clearInterval(idSetInterval.value);
            loadingMessage.value = '';
        });
        return {
            audioName,
            idSetInterval,
            loadingMessage,
            isPlaying,
            audioVariations,
            currentVariation,
            buildState,
            url1,
            url2,
            url3,
            idEntSession,
            alertMessage,
            alert1,
            videoEl,
            audioEl,
            videoFile,
            audioFile,
            urlVideo,
            urlAudio,
            checkCurrentState,
            clearLoadingMessage,
            clickVariation,
            videoUploaded,
            audioUploaded,
            removeVideo,
            removeAudio,
            onError,
            buildVariation,
            abortBuild
        }
    },
}
</script>
<style scoped>
.btn-floating{
    font-size: 0.85rem !important;
}
.btn-floating .fas{
    width: 2rem;
    line-height: 2.1rem;
}
video{
    width: 100%;
    height: 100%;
}
.lecteur-video{
    height: 350px;
}
</style>
