import { createI18n } from 'vue-i18n'
import messages from '@/lang/messages'
/*
let userLanguage = navigator.language
let locale = userLanguage.split('-')[0]
if (locale !== 'fr' && locale !== 'en') locale = 'en' // en by default*/
// on utilise la version anglaise pour le moment
let locale = 'en';

export default createI18n ({
    locale: locale,
    fallbackLocale: 'fr',
    messages

});