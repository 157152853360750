<template>
    <div class="row">
        <h2 id="MuseMind">{{$t('presentationBlock.title')}}</h2>
        <p>
            <strong>{{$t('global.name')}}</strong>
            {{$t('presentationBlock.description.textOne')}}
        </p>
        <p>
            <strong>{{$t('global.name')}}</strong>
            {{$t('presentationBlock.description.textTwo')}}
        </p>
        <MDBLightbox>
            <MDBRow>
                <MDBCol lg="6" class="mb-4">
                    <MDBLightboxItem
                    :src="regroupement"
                    :fullScreenSrc="regroupement"
                    alt="Music AutoEditor"
                    caption=" "
                    class="w-100"
                    />
                </MDBCol>
                <MDBCol lg="6" class="mb-4">
                    <p>{{$t('presentationBlock.description.textThree')}}</p>
                    <p>
                        In Basic Mode, <strong>{{$t('global.name')}}</strong>
                        {{$t('presentationBlock.mainContent.left.textOne')}}
                    </p>
                </MDBCol>
                <MDBCol lg="12" class="mb-4">
                    <p>
                        <strong>{{$t('global.name')}}</strong>
                        {{$t('presentationBlock.mainContent.left.textTwo')}}
                    </p>
                </MDBCol>
                <MDBCol lg="6" class="mb-4">
                    <MDBLightboxItem
                    :src="v57Advanced"
                    :fullScreenSrc="v57Advanced"
                    caption=" "
                    alt="Music AutoEditor"
                    class="w-100"
                    />
                </MDBCol>
                <MDBCol lg="6" class="mb-4">
                  <p>
                      In Advanced Mode, <strong>{{$t('global.longName')}}</strong>
                      {{$t('presentationBlock.mainContent.right.textOne')}}
                  </p>
                  <ul>
                      <li>{{$t('presentationBlock.mainContent.right.list.textOne')}}</li>
                      <li>{{$t('presentationBlock.mainContent.right.list.textTwo')}}</li>
                      <li>{{$t('presentationBlock.mainContent.right.list.textThree')}}</li>
                      <li>{{$t('presentationBlock.mainContent.right.list.textFour')}}</li>
                  </ul>
                </MDBCol>
                <!--
                <MDBCol lg="6" class="mb-4">
                    <MDBLightboxItem
                    :src="v57Vide"
                    :fullScreenSrc="v57Vide"
                    alt="Lightbox image 2"
                    caption=" "
                    class="w-100"
                    />
                </MDBCol>
                <MDBCol lg="6" class="mb-4">
                    <MDBLightboxItem
                    :src="v57Basic"
                    :fullScreenSrc="v57Basic"
                    alt="Lightbox image 3"
                    caption=" "
                    class="w-100"
                    />
                </MDBCol>
                -->
            </MDBRow>
        </MDBLightbox>
        <div class="row">
            <div class="col-md-12">
                <p>{{$t('presentationBlock.mainContent.footer.textOne')}}</p>
                <p>{{$t('presentationBlock.mainContent.footer.textTwo')}}</p>
            </div>
            <hr>
        </div>
    </div>
</template>
<script>
import splashscreen from '@/assets/images/splashscreen.png';
import regroupement from '@/assets/images/regroupement.png';
import v57Vide from '@/assets/images/v57_vide.png';
import v57Basic from '@/assets/images/v57_basic.png';
import v57Advanced from '@/assets/images/v57_advanced.png';
import { MDBLightbox, MDBLightboxItem, MDBCol, MDBRow } from 'mdb-vue-ui-kit';
export default {
    components: {
        MDBLightbox,
        MDBLightboxItem,
        MDBCol,
        MDBRow
    },
    setup() {
        return{
            splashscreen,
            regroupement,
            v57Vide,
            v57Advanced,
            v57Basic
        }
    },
}
</script>
<style scoped>

</style>
