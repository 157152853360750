import 'mdb-vue-ui-kit/css/mdb.min.css'
import 'mdb-vue-file-upload/css/mdb-vue-file-upload.min.css'
import './assets/scss/styles.scss'

import 'fontawesome-pro/scss/fontawesome.scss';
import 'fontawesome-pro/scss/solid.scss';
import 'fontawesome-pro/scss/brands.scss'
import 'fontawesome-pro/js/fontawesome';
import 'fontawesome-pro/js/solid';
import { createApp } from 'vue'
import i18n from '@/utils/i18n'
import App from './App.vue'

createApp(App).use(i18n).mount('#app')
