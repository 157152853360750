import { Buffer } from 'buffer';
import getEnv from '@/utils/env';

function generateIdEntSession(length = 16){
    const charset = '0123456789';
    let id = '';
    for (let index = 0, n = charset.length;  index < length; ++index) {
        id += charset.charAt(Math.floor(Math.random() * n));
    }
    return id;
}

function getAuthorizationToken(){
    return Buffer.from(getEnv('VUE_APP_AUTH')).toString('base64');
}

function authenticationData(){
    return {
        idEntSession: generateIdEntSession(),
        authorizationToken: getAuthorizationToken()
    }
}

export default {
    authenticationData
}
