export default {
    mainBlock: {
        presentation: "Music AutoEditor est une solution basée sur l'IA pour l'édition automatique de musique sur une vidéo.",
        try: "Essayez-le gratuitement",
        steps: {
            textOne: "déposez votre vidéo",
            textTwo: "déposez votre musique",
            textThree: "lancez l'édition"
        },
        contact: {
            header: "Des questions ? Besoin d'une démonstration ? Si vous êtes intéressés, contactez-nous.",
            politePhrase: "Vous pouvez contacter ... au :",
            person: {
                name: 'Nicolas',
                mail: 'nlemonnier@musemind-music.com',
                tel: '07 63 30 27 99'
            }
        },
        buttons: {
            discover: {
                text: 'DÉCOUVREZ MUSEMIND'
            }
        }

    }
}