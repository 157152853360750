<template>
  <div class="container-md">
    <MainBlock/>
    <PresentationBlock/>
    <FooterBlock/>
  </div>
  <footer>{{$t('global.footer')}}</footer>

</template>

<script>
import MainBlock from '@/components/views/MainBlock.vue';
import PresentationBlock from '@/components/views/PresentationBlock.vue';
import FooterBlock from '@/components/views/FooterBlock.vue';

export default {
  name: 'App',
  components: {
    MainBlock,
    PresentationBlock,
    FooterBlock
  }
}
</script>

<style lang="scss">
</style>
